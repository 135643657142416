<template>
    <div id="addCompetition">

        <!--## Blocco tabella miei ultimi concorsi ##-->
        <div class="card">
            <div class="card-body">
                <div class="float-right mb-2 ml-2">
                    <router-link to="/banche-dati" class="btn btn-danger btn-sm btn-rounded"><i class="mdi mdi-delete-alert-outline"></i> {{$t('generale.annulla')}}</router-link>
                </div>

                <h5 class="header-title mb-4 text-uppercase"><i class="mdi mdi-file-table-box-multiple-outline font-size-24 mr-1 align-middle"></i> {{$t('addCompetition.aggiungiNuovoConcorso')}}</h5>

                <div class="row">
                    <div class="col-sm-3">
                        <div id="bancheDatiTabs" class="nav flex-column nav-pills" role="tablist" aria-orientation="vertical">
                            <a class="nav-link active mb-2" id="v-pills-left-home-tab" data-toggle="pill" href="#v-pills-left-home" role="tab" aria-controls="v-pills-left-home" aria-selected="true">
                                <i class="mdi mdi-clipboard-list-outline mr-1 mdi-24px align-middle"></i> {{$t('addCompetition.ente')}}
                            </a>
                            <a class="nav-link mb-2" id="v-pills-left-profile-tab" data-toggle="pill" href="#v-pills-left-profile" role="tab" aria-controls="v-pills-left-profile" aria-selected="false">
                                <i class="mdi mdi-account-check-outline mr-1 mdi-24px align-middle"></i> {{$t('addCompetition.profilo')}}
                            </a>
                            <a class="nav-link mb-2" id="v-pills-left-messages-tab" data-toggle="pill" href="#v-pills-left-messages" role="tab" aria-controls="v-pills-left-messages" aria-selected="false">
                                <i class="mdi mdi-new-box mdi-24px align-middle"></i> {{$t('addCompetition.ultimiInseriti')}}
                            </a>
                        </div>
                    </div>
                    <div class="col-sm-9">
                        <div class="tab-content mt-4 mt-sm-0">
                            <div class="tab-pane fade show active" id="v-pills-left-home" role="tabpanel" aria-labelledby="v-pills-left-home-tab">
                                <h4 class="header-title mb-3">{{$t('addCompetition.aggiungiUnNuovoConcorsoUtilizzandoIFiltri')}}</h4>

                                <form id="selectize-form">
                                    <div class="form-group mb-0">
                                        <label>{{$t('addCompetition.selezionaEnte')}}</label>
                                        <select id="select-entity" class="selectize">
                                            <option value="">{{$t('generale.aggiungi')}}...</option>
                                        </select>
                                    </div>
                                    <div class="form-group mb-0">
                                        <label>{{$t('addCompetition.selezionaConcorso')}}</label>
                                        <select id="select-competition" class="selectize">
                                            <option value="">{{$t('generale.aggiungi')}}...</option>
                                        </select>
                                    </div>
                                    <div class="form-group mb-0">
                                        <label>{{$t('addCompetition.selezionaAnno')}}</label>
                                        <select id="select-year" class="selectize">
                                            <option value="">{{$t('generale.aggiungi')}}...</option>
                                        </select>
                                    </div>
                                </form>

                                <h4 class="header-title mt-5 mb-2">{{$t('addCompetition.riepilogoConcorso')}}</h4>

                                <div class="table-responsive">
                                    <table class="table mb-0">
                                        <tbody>
                                            <tr>
                                                <th class="w-25">{{$t('addCompetition.ente')}}</th>
                                                <th><strong>{{competitionSelected.ente.ente}}</strong></th>
                                            </tr>
                                            <tr>
                                                <th class="w-25" scope="row">{{$t('addCompetition.concorso')}}</th>
                                                <th><strong>{{competitionSelected.concorso}}</strong></th>
                                            </tr>
                                            <tr>
                                                <th class="w-25" scope="row">{{$t('addCompetition.anno')}}</th>
                                                <th><strong>{{competitionSelected.anno}}</strong></th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="form-group mt-5 mb-0 float-right">
                                    <div>
                                        <button v-if="verifyIfCompetitionIsAlreadyAddedByUser()" type="button" @click="goToCompetition(competitionSelected.id_concorso)" class="btn btn-primary waves-effect waves-light mr-3">{{$t('generale.apri')}}</button>
                                        <button v-else type="button" :class="[((competitionSelected.anno === '') ? 'cursorNotAllowed' : ''), 'btn btn-primary waves-effect waves-light mr-3']" id="sa-success" :disabled="(competitionSelected.anno === '')" @click="addCompetition(null, 'ente')">{{$t('generale.aggiungi')}}</button>

                                        <button type="button" class="btn btn-secondary waves-effect" @click="resetForm()">
                                            {{$t('generale.annulla')}}
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div class="tab-pane fade" id="v-pills-left-profile" role="tabpanel" aria-labelledby="v-pills-left-profile-tab">

                                <div class="row pb-2">
                                    <div class="offset-8 col-4 text-right">
                                        <div class="d-inline">{{$t('generale.cerca')}}: </div>
                                        <div class="d-inline">
                                            <input class="form-control w-75 d-inline-block" v-model.trim="search"/>
                                        </div>
                                    </div>
                                </div>

                                <div id="accordion">
                                    <template v-if="concorsiAccordion.length > 0">
                                        <template v-for="(competition, index) in concorsiAccordion">
                                            {{changeArrowAccordion('#collapse' + competition.id, competition.id)}}
                                            <div :key="competition.id" :class="['card mb-0', ((competition.concorsi && competition.concorsi.length > 0) ? '' : 'd-none')]" v-if="competition.id !== ''">
                                                <div class="card-header" :id="'heading' + competition.id">
                                                    <h5 class="m-0 font-size-14">
                                                        <a data-toggle="collapse" data-parent="#accordion" :href="'#collapse' + competition.id" aria-expanded="true" :aria-controls="'collapse' + competition.id" class="text-dark">
                                                            <i :id="'icon-arrow' + competition.id" class="mdi mr-3 mdi-chevron-right mdi-18px align-middle"></i> {{competition.name}}
                                                        </a>
                                                    </h5>
                                                </div>

                                                <div :id="'collapse' + competition.id" class="collapse" :aria-labelledby="'heading' + competition.id" data-parent="#accordion">
                                                    <div class="card-body">
                                                        <div class="table-responsive">
                                                            <table class="table table-striped mb-0">
                                                                <tbody>
                                                                <template v-for="competitionByEntity in competition.concorsi">
                                                                    <tr :key='"internal-"+competitionByEntity.id_concorso'>
                                                                        <th class="verticalCenter" style="width: 60%!important;" scope="row">{{competitionByEntity.ente.ente}}</th>
                                                                        <td class="verticalCenter" style="width: 20%!important;">{{competitionByEntity.anno}}</td>
                                                                        <td class="text-right" style="width: 20%!important;">
                                                                            <button v-if="verifyIfCompetitionIsAlreadyAddedByUser(competitionByEntity.id_concorso)" type="button" @click="goToCompetition(competitionByEntity.id_concorso)" class="btn btn-primary btn-rounded btn-sm btn-block waves-effect waves-light">{{$t('generale.apri')}}</button>
                                                                            <button v-else type="button" @click="addCompetition(competitionByEntity.id_concorso, 'other')" class="btn btn-primary btn-rounded btn-sm btn-block waves-effect waves-light">
                                                                                {{$t('generale.aggiungi')}}
                                                                                <i class="mdi mdi-arrow-right-bold-circle-outline"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <div class="text-center pt-2">
                                            {{$t('addCompetition.laRicercaNonHaPortatoRisultati')}}
                                        </div>
                                    </template>
                                </div>
                            </div>

                            <div class="tab-pane fade" id="v-pills-left-messages" role="tabpanel" aria-labelledby="v-pills-left-messages-tab">
                                <h4 class="header-title">{{$t('addCompetition.elencoUltimiConcorsiInseriti')}}</h4>
                                <template v-if="lastAddedCompetitions.length > 0">
                                    <p class="card-title-desc">{{$t('addCompetition.effettuaUnaRicercaOppureFiltraIConcorsi')}}</p>
                                    <table id="datatable" class="table table-bordered dt-responsive" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                                        <thead>
                                            <tr>
                                                <th class="d-none"></th>
                                                <th style="width: 35px!important">{{$t('addCompetition.anno')}}</th>
                                                <th>{{$t('addCompetition.ente')}}</th>
                                                <th>{{$t('addCompetition.concorso')}}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(lastAddedCompetition, i) in lastAddedCompetitions">
                                                <tr :key="i">
                                                    <th class="d-none">{{lastAddedCompetition.data_ultimo_aggiornamento}}</th>
                                                    <th scope="row" class="text-break">{{lastAddedCompetition.anno}}</th>
                                                    <td class="text-break">{{lastAddedCompetition.ente.ente}}</td>
                                                    <td class="text-break">{{lastAddedCompetition.concorso}}</td>
                                                    <td>
                                                        <button v-if="verifyIfCompetitionIsAlreadyAddedByUser(lastAddedCompetition.id_concorso)" type="button" @click="goToCompetition(lastAddedCompetition.id_concorso)" class="btn btn-primary btn-rounded btn-sm btn-block waves-effect waves-light">{{$t('generale.apri')}}</button>
                                                        <button v-else type="button" @click="addCompetition(lastAddedCompetition.id_concorso, 'other')" class="btn btn-primary btn-rounded btn-block btn-sm waves-effect waves-light">{{$t('generale.aggiungi')}}</button>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </template>
                                <template v-else>
                                    <div class="text-center mt-1">{{$t('addCompetition.nonCiSonoBancheDati')}}</div>
                                </template>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!--## Fine Blocco tabella miei ultimi concorsi ##-->

        <!-- Modal generic error -->
        <div id="modalGenericErrorAddCompetition" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mmodalGenericErrorAddCompetitionLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalGenericErrorAddCompetitionLabel">{{$t('addCompetition.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-generic-add-error" class="modal-body">
                        <p id="message-body-generic-add-error"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal add competition error -->

        <!-- Modal add competition error -->
        <div id="modalErrorAddCompetition" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalErrorAddCompetitionLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalErrorAddCompetitionLabel">{{$t('addCompetition.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-add-error" class="modal-body">
                        <p id="message-body-add-error"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: 'Home' })">{{$t('generale.annulla')}}</button>
                        <button type="button" id="button-retry-add" class="btn btn-primary waves-effect waves-light" @click="sendAllRequests()" data-dismiss="modal">{{$t('generale.riprova')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal add competition error -->

        <!-- Modal competitions request error -->
        <div id="modalCompetitionsRequestsError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalCompetitionsRequestsErrorLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalCompetitionsRequestsErrorLabel">{{$t('competitions.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-competitions-requests-error" class="modal-body">
                        <p id="message-body-competitions-requests-error"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" id="button-ok-competitions" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: 'Home' })">{{$t('generale.annulla')}}</button>
                        <button type="button" id="button-retry-competitions" class="btn btn-primary waves-effect waves-light" @click="getUtenteConcorsi()" data-dismiss="modal">{{$t('generale.riprova')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal competitions request error -->

    </div>
</template>

<script>
    import $ from 'jquery'
    import 'selectize'
    import { mapGetters, mapActions } from 'vuex'
    import { MODULES } from "@/store/modules-enum";
    import { AUTH_GETTERS } from "@/store/auth/getters-enum";
    import { COMPETITION_ACTIONS } from "@/store/competition/actions-enum";
    import { COMPETITION_GETTERS } from "@/store/competition/getters-enum";
    import _ from 'lodash'
    import 'datatables.net-bs4'
    import {UTILITY_MUTATIONS} from "@/store/utility/mutations-enum";
    import {UTILITY_ACTIONS} from "@/store/utility/actions-enum";
    import {PATH_ACTIONS} from "@/store/path/actions-enum";
    import {PATH_GETTERS} from "@/store/path/getters-enum";
    import {getDistanceWith} from "@/lib/utils";
    import {systemTimeDelay} from "@/config";
    import {isTimeChecked, setTimeChecked} from "@/lib/utils";
    import {UTILITY_GETTERS} from "@/store/utility/getters-enum";
    import {PROFILE_ACTIONS} from "@/store/profile/actions-enum";
    import {routesEnums} from "@/helpers/enums";

    export default {
        name: "AddCompetition",
        data () {
            return {
                search: '',
                competitionsWithoutDuplicatedOrSearched: [],
                selectizeEntity: {},
                selectizeCompetition: {},
                selectizeYear: {},
                idCompetition: '',
                competitionSelected: {
                    id_concorso: '',
                    ente: {
                        ente: ''
                    },
                    anno: '',
                    concorso: ''
                },
                competitionsGrouped: {},
                requests: []
            }
        },
        computed: {
            ...mapGetters(MODULES.AUTH, [AUTH_GETTERS.AUTHENTICATIONS]),
            ...mapGetters(MODULES.COMPETITION, [COMPETITION_GETTERS.ENTITIES, COMPETITION_GETTERS.COMPETITIONS, COMPETITION_GETTERS.USER_COMPETITIONS, COMPETITION_GETTERS.GROUPED_COMPETITIONS, COMPETITION_GETTERS.ENTITIES]),
            ...mapGetters(MODULES.PATH, [PATH_GETTERS.PATHS]),
            ...mapGetters(MODULES.UTILITY, [UTILITY_GETTERS.GET_LOCAL_UPTIME]),
            lastAddedCompetitions: function() {
                const lastCompetition = _.filter(this.competitions, function (c) {
                    return c.stato === 1 || c.stato === 2
                })
                return _.orderBy(lastCompetition,  [(obj) => new Date(obj.data_inserimento)], ['desc'])
            },
            entitiesOrdered: function() {
                return _.orderBy(this.entities, ['ente'], ['asc']);
            },
            concorsiAccordion: function() {
                if (!this.groupedCompetitions) {
                    return [];
                }
                return _.sortBy(
                    Object.entries(this.groupedCompetitions)
                        .map(([nomeConcorso, concorsi]) => {
                            return {
                                name: nomeConcorso.trim(),
                                id: concorsi[0].id_concorso || nomeConcorso.toLowerCase().replace(/ /g, "-"),
                                concorsi: concorsi.filter(c =>
                                    _.includes(c.concorso.toLowerCase(), this.search.toLowerCase()) ||
                                    _.includes(c.ente.ente.toLowerCase(), this.search.toLowerCase())
                                )
                            }
                        }),
                    'name'
                )
            }
        },
        watch: {
            entitiesOrdered: function(newValue, oldValue) {
                if (newValue.length > 0) { this.fillSelectEntity(this.selectizeEntity) }
            },
            idCompetition: function(newValue, oldValue) {
                if (newValue !== null && newValue !== '') {
                    this.competitionSelected = _.cloneDeep(_.find(this.competitions, function (c) { return c.id_concorso === newValue }))
                }
            },
        },
        mounted() {
            this.backToTop()
            this.verifyUptime()
        },
        methods: {
            ...mapActions(MODULES.COMPETITION, [COMPETITION_ACTIONS.GET_ENTITIES, COMPETITION_ACTIONS.GET_COMPETITIONS, COMPETITION_ACTIONS.GET_COMPETITION, COMPETITION_ACTIONS.GET_USER_COMPETITIONS, COMPETITION_ACTIONS.SET_SELECTED_COMPETITION]),
            ...mapActions(MODULES.PATH, [PATH_ACTIONS.GET_PATHS]),
            ...mapActions(MODULES.UTILITY, [UTILITY_ACTIONS.GET_UPTIME]),
            ...mapActions(MODULES.PROFILE, [PROFILE_ACTIONS.GET_USER]),
            verifyUptime () {
                this.$emit('is-components-ready', 'addCompetition', false)
                const context = this
                this.getUptime()
                    .then((res) => {
                        context.verifySystemTime(res.headers.date)
                        if (context.competitions.length < 1 || context.entities.length < 1 || context.paths.length < 1 ) {
                            context.sendAllRequests(false, res.body)
                        } else {
                            if (res.body > context.getLocalUptime) {
                                context.sendAllRequests(false, res.body)
                            } else {
                                context.sendAllRequests(true)
                            }
                        }
                    })
                    .catch(() => {
                        context.sendAllRequests(false)
                    })
                    .finally(() => {
                        context.getUtenteConcorsi()
                        context.initSelectizeAndDT(context)
                        context.setCompetitionsWithoutDuplicatedOrSearched()
                        context.groupByCompetition(context.competitions)
                        context.selectInitialTab()
                    })
            },
            verifySystemTime (headersDate) {
                if (!isTimeChecked()) {
                    setTimeChecked(true);
                    const timeDiff = getDistanceWith(headersDate)
                    if (timeDiff > systemTimeDelay) {
                        $('#modalTime').modal('show')
                    }
                }
            },
            sendAllRequests (onlyUser, uptime) {
                this.isRequestsReady('sendAllRequests', false)
                $('#modalErrorAddCompetition').modal('hide')
                const context = this
                $('#button-retry-add').attr('disabled', 'disabled')
                const setupPromise = context.getUser(true).then(res => {
                    if (res.userCleared || !onlyUser) {
                        return Promise.all([
                            context.getCompetitions(context),
                            context.getEntities(context),
                            context.getPaths(context)
                        ])
                    }
                })
                setupPromise
                    .then(() => {
                        if (uptime !== undefined && uptime !== null) {
                            this.$store.commit(MODULES.UTILITY + "/" + UTILITY_MUTATIONS.SET_UPTIME, uptime)
                        }
                        this.isRequestsReady('sendAllRequests', true)
                    })
                    .catch(() => {
                        context.$emit('is-components-ready', 'addCompetition', true)
                        $('#button-retry-add').removeAttr('disabled')
                        $('#modalErrorAddCompetition').data('bs.modal', null)
                        $('#modalErrorAddCompetition').modal({backdrop: 'static', keyboard: false})
                        $('#message-body-add-error').html(context.$t('layouts.main.modale.errore.richieste'))
                    })
            },
            initSelectizeAndDT (context) {
                $('#selectize-form').css('visibility', 'hidden');
                $( document ).ready(function() {
                    context.initDT()
                    context.selectizeEntity = $('#select-entity').selectize({
                        score: function(search) {
                            return function(item) {
                                const string = item.text.replace(/\./g, '').toLowerCase()
                                if (string.search(search) >= 0 || item.desc.search(search?.toLowerCase()) >= 0) {
                                    return true
                                }
                            };
                    }
                    })[0].selectize
                    context.selectizeCompetition = $('#select-competition').selectize({
                        score: function(search) {
                            return function(item) {
                                const string = item.text.toLowerCase()
                                if (string.search(search) >= 0 || item.desc.search(search?.toLowerCase()) >= 0) {
                                    return true
                                }
                            };
                        }
                    })[0].selectize
                    context.selectizeYear = $('#select-year').selectize()[0].selectize
                    $('#selectize-form').css('visibility', 'visible');
                    context.selectizeCompetition.disable()
                    context.selectizeYear.disable()
                    context.fillSelectEntity(context.selectizeEntity)
                    context.onChangeSelectEntity(context.selectizeEntity, context.selectizeCompetition, context.selectizeYear, context)
                    context.onChangeSelectCompetition(context.selectizeCompetition, context.selectizeYear, context)
                    context.onChangeSelectYear(context.selectizeYear, context)
                });
            },
            initDT () {
                let dt = null
                $('.nav-pills #v-pills-left-messages-tab').on('shown.bs.tab', function(e) {
                    dt = $('#datatable').DataTable({
                        order: [],
                        language: {
                            url: '//cdn.datatables.net/plug-ins/1.10.21/i18n/Italian.json'
                        }
                    });
                    $( ".dataTable" ).parent().addClass( "overflow-table" );
                })
                $('.nav-pills #v-pills-left-messages-tab').on('hidden.bs.tab', function(e) {
                    dt.destroy()
                })
            },
            fillSelectEntity (selectizeEntity) {
                this.addDescriptionToEntities();
                this.entitiesOrdered.forEach(entity => {
                    selectizeEntity.addOption({ value: entity.id_ente, text: entity.ente, desc: entity.desc || ''});
                })
            },
            addDescriptionToEntities () {
                const index = this.entitiesOrdered.findIndex(entity => {
                    return entity.ente?.toLowerCase() === 'g.d.f.';
                })
                this.entitiesOrdered[index].desc = 'guardia di finanza'
            },
            onChangeSelectEntity (selectizeEntity, selectizeCompetition, selectizeYear, context) {
                selectizeEntity.on('change', (value) => {
                    context.resetSelectizeForm(context, selectizeCompetition, selectizeYear)
                    context.competitionSelected.ente.ente = (value !== '') ? _.cloneDeep(_.find(context.entities, function(e) { return e.id_ente === value })).ente : ''
                    const competitions = _.filter(context.competitions, function(c) { return c.ente.id_ente === value });
                    if (competitions.length > 0) {
                        context.addOptions(competitions, selectizeCompetition, 'concorso', 'asc')
                        selectizeCompetition.enable()
                    }
                })
            },
            resetSelectizeForm(context, selectizeCompetition, selectizeYear) {
                selectizeCompetition.disable()
                selectizeYear.disable()
                selectizeCompetition.clear(true)
                selectizeCompetition.clearOptions()
                selectizeYear.clear(true)
                selectizeYear.clearOptions()
                context.competitionSelected.concorso = ''
                context.competitionSelected.anno = ''
            },
            onChangeSelectCompetition (selectizeCompetition, selectizeYear, context) {
                selectizeCompetition.on('change', (value) => {
                    context.competitionSelected.anno = ''
                    selectizeYear.clear(true)
                    selectizeYear.clearOptions()
                    context.competitionSelected.concorso = value
                    const competitions = _.filter(context.competitions, function(c) { return c.concorso === value && context.competitionSelected.ente.ente === c.ente.ente });
                    if (competitions.length > 0) {
                        context.addOptions(competitions, selectizeYear, 'anno', 'desc')
                        selectizeYear.enable()
                    } else {
                        selectizeYear.disable()
                    }
                })
            },
            onChangeSelectYear (selectizeYear, context) {
                selectizeYear.on('change', (value) => {
                    context.idCompetition = value
                    context.competitionSelected.anno = (value !== '') ? _.cloneDeep(_.find(context.competitions, function(c) { return c.id_concorso === value })).anno : ''
                })
            },
            addOptions (competitions, selectSelector, type, order) {
                const competitionsWithoutDuplicated = this.getCompetitionsWithoutDuplicated(competitions, type)
                const competitionsOrdered = _.orderBy(((type === 'concorso') ? competitionsWithoutDuplicated : [...competitions]), [type], [order])
                competitionsOrdered.forEach(competition => {
                    if (competition.concorso !== '' && competition.anno !== '') {
                        selectSelector.addOption({ value: competition[((type === 'concorso') ? 'concorso' : 'id_concorso')], text: competition[type], desc: competition.desc || ''})
                    }
                })
                selectSelector.refreshOptions()
            },
            getCompetitionsWithoutDuplicated (competitions, type, searched) {
                if (searched) {
                    return [..._.uniqWith(competitions, function(arrVal, othVal) {
                        return arrVal.concorso === othVal.concorso && othVal.show === true;
                    })]
                } else {
                    return [..._.uniqBy(competitions, _.iteratee(type))]
                }
            },
            addCompetition (id_concorso, type) {
                if (this.competitionSelected.anno !== '' || type === 'other') {
                    this.$emit('is-components-ready', 'addCompetition', false)
                    const context = this
                    this.getCompetition({
                        idConcorso: (id_concorso !== null) ? id_concorso : context.idCompetition,
                        opts: null
                    })
                        .then(() => {
                            context.getUtenteConcorsi((id_concorso !== null) ? id_concorso : context.idCompetition)
                        })
                        .catch(() => {
                            context.$emit('is-components-ready', 'addCompetition', true)
                            $('#modalGenericErrorAddCompetition').modal('show')
                            $('#message-body-generic-add-error').html(context.$t('home.modale.errore.aggiungiConcorso'))
                        })
                }
            },
            getUtenteConcorsi (id_concorso) {
                this.isRequestsReady('getUserCompetitions', false)
                this.$emit('is-components-ready', 'addCompetition', false)
                const context = this
                this.getUserCompetitions()
                    .then(() => {
                        if (id_concorso) {
                            context.goToCompetition(id_concorso)
                        } else {
                            context.isRequestsReady('getUserCompetitions', true)
                        }
                    })
                    .catch(() => {
                        context.$emit('is-components-ready', 'addCompetition', true)
                        $('#button-retry-add').removeAttr('disabled')
                        $('#modalCompetitionsRequestsError').data('bs.modal', null)
                        $('#modalCompetitionsRequestsError').modal({backdrop: 'static', keyboard: false})
                        $('#message-body-competitions-requests-error').html(context.$t('home.modale.errore.concorsiUtente'))
                    })
            },
            resetForm () {
                this.selectizeCompetition.clearOptions()
                this.selectizeYear.clearOptions()
                this.selectizeEntity.clear()
                this.selectizeCompetition.clear()
                this.selectizeYear.clear()
                this.idCompetition = ''
                this.competitionSelected = { ente: { ente: '' }, anno: '', concorso: '' }
            },
            verifyIfCompetitionIsAlreadyAddedByUser (id_concorso) {
                const context = this
                if (id_concorso !== null && id_concorso !== undefined && id_concorso !== '') {
                    return this.filterCompetitionsAddedByUser(id_concorso)
                }
                if (this.competitionSelected.anno !== null && this.competitionSelected.anno !== '' && this.idCompetition !== null && this.idCompetition !== '') {
                    return this.filterCompetitionsAddedByUser(context.idCompetition)
                }
                return false
            },
            goToCompetition(idConcorso) {
                let context = this
                this.setSelectedCompetition(idConcorso)
                    .then(() => {
                        context.$emit('is-components-ready', 'addCompetition', true)
                        this.$router.push({ name: routesEnums.COMPETITION_DETAIL_WRAPPER })
                    })
                    .catch(() => {
                        context.$emit('is-components-ready', 'addCompetition', true)
                        $('#modalGenericErrorAddCompetition').modal('show')
                        $('#message-body-generic-add-error').html(context.$t('home.modale.errore.concorsiUtente'))
                    })
            },
            filterCompetitionsAddedByUser(filter) {
                const competitionAddedByUser = _.filter(this.userCompetitions, function (userCompetition) {
                    return userCompetition.id_concorso === filter
                })
                return competitionAddedByUser.length > 0;
            },
            groupByCompetition (competitions) {
                this.competitionsGrouped = _.groupBy(competitions, _.iteratee('concorso'))
            },
            setCompetitionsWithoutDuplicatedOrSearched(search) {
                let competitions = []
                _.forEach(_.cloneDeep(this.competitions), function (c) {
                    if (search !== null && search !== undefined && search !== '') {
                        c.show = _.includes(c.concorso.toLowerCase(), search.toLowerCase()) || _.includes(c.ente.ente.toLowerCase(), search.toLowerCase())
                    }
                    else {
                        c.show = true
                    }
                    competitions.push(c)
                })
                this.groupByCompetition(_.filter(competitions, (c) => { return c.show === true }))
                const competitionsWithoutDuplicated = this.getCompetitionsWithoutDuplicated(competitions, 'concorso', ((search !== null && search !== undefined && search !== '')))
                const competitionWithoutDuplicatedAndOrdered = _.orderBy(competitionsWithoutDuplicated, ['concorso'], ['asc']);
                if (_.find(competitionWithoutDuplicatedAndOrdered, (c) => { return c.show === true }) !== undefined) {
                    this.competitionsWithoutDuplicatedOrSearched = competitionWithoutDuplicatedAndOrdered
                } else {
                    this.competitionsWithoutDuplicatedOrSearched = []
                }
            },
            changeArrowAccordion (stringElement, index) {
                const stringIconElement = '#icon-arrow' + index
                $(stringElement).off('show.bs.collapse').on('show.bs.collapse', function () {
                    $(stringIconElement).removeClass('mdi-chevron-right')
                    $(stringIconElement).addClass('mdi-chevron-down')
                })
                $(stringElement).off('hide.bs.collapse').on('hide.bs.collapse', function () {
                    $(stringIconElement).removeClass('mdi-chevron-down')
                    $(stringIconElement).addClass('mdi-chevron-right')
                })
            },
            backToTop () {
                document.body.scrollTop = 0
                document.documentElement.scrollTop = 0
            },
            setRequests () {
                this.requests = [
                    {
                        label: 'sendAllRequests',
                        isComplete: true
                    },
                    {
                        label: 'getUserCompetitions',
                        isComplete: true
                    }
                ]
            },
            isRequestsReady (label, state) {
                const request = _.find(this.requests, (r) => r.label === label)
                if (request !== undefined) {
                    request.isComplete = state
                }
                const isRequestPending = _.find(this.requests, (r) => r.isComplete === false)
                if (isRequestPending === undefined) {
                    this.$emit('is-components-ready', 'addCompetition', true)
                }
            },
            selectInitialTab() {
                const hash = this.$router.currentRoute.hash;
                if (hash && hash !== "") {
                    $(`#bancheDatiTabs a[href="${hash}"]`).tab('show')
                }
            }
        }
    }
</script>

<style scoped lang="css">
    @import "~selectize/dist/css/selectize.css";
    @import "~datatables.net-bs4/css/dataTables.bootstrap4.min.css";
    @import "~datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
</style>

<style scoped lang="scss">
    html.theme-light, html.theme-dark {
        @media (max-width: 1279px) {
            .overflow-table {
                overflow: auto;
            }
        }
    }
</style>
